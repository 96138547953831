<template>
	<div class="commodityDetails">
		<div class="flex_r f_jc_b account pt30 pb30 br_1_eee">
			<div class="flex_r f_ai_c pl10">
				<div class="posr account_img">
					<img class="brs_4" height="88" width="88" :src="goods.pict_url" alt="">
				</div>
				<div class="flex_c f_jc_c pl15">
					<span class="pb10 fs18">{{goods.title}}</span>
					<el-tag class="w_75 tc mt10 mb10">{{goods.name}}</el-tag>
					<span class="pt10 pb10">在售价 ￥{{goods.s_price}}-{{goods.e_price}}</span>
					<span>规格</span>
				</div>
			</div>
			<ul class="flex_c f_jc_c pr20 br_l_1_eee">
<!--				<li class="flex_c f_jc_c f_ai_c">-->
<!--					<img width="80" height="80" :src="goods.pict_url" alt="" srcset="">-->
<!--				</li>-->
				<li class="flex_r f_jc_c f_ai_c mb4">
					<img width="16" v-if="goods.web == 0" height="16" src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/tb.png" alt="" srcset="">
					<img width="16" v-if="goods.web == 1" height="16" src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/tm.png" alt="" srcset="">
					<span>{{goods.nick}}</span>
				</li>
				<li class="flex_c f_jc_c f_ai_c">
					<el-button size="mini" round>品牌方</el-button>
				</li>
			</ul>
		</div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="内部直播数据" name="1"></el-tab-pane>
			<el-tab-pane label="全网直播数据" name="2"></el-tab-pane>
		</el-tabs>
		<el-row :gutter="10" class="mb30">
			<el-col :span="12">
				<e-card title="主播销售额占比">
					<div slot="title">
					</div>
					<div class="echart">
						<e-charts id="idPie" ref="echarts" :options="optionPie" class="h100" />
						<!-- <Empty /> -->
					</div>
				</e-card>
			</el-col>
			<e-card title="主播销售额占比">
				<el-row :gutter="20" class="echart flex_c f_jc_a">
					<div>
						<el-col :span="8" class="flex_c f_jc_c tc">
							<span class="fs16 cr_999">总销量</span>
							<span class="fs18 cr_ff0 pt20">{{data.cjs}}件</span>
						</el-col>
						<el-col :span="8" class="flex_c f_jc_c tc">
							<span class="fs16 cr_999">总销售额</span>
							<span class="fs18 cr_ff0 pt20">{{data.xse}}元</span>
						</el-col>
						<el-col :span="8" class="flex_c f_jc_c">
						</el-col>
					</div>
					<div>
<!--						<el-col :span="8" class="flex_c f_jc_c tc">-->
<!--							<span class="fs16 cr_999">直播人数</span>-->
<!--							<span class="fs18 cr_ff0 pt20">0人</span>-->
<!--						</el-col>-->
						<el-col :span="8" class="flex_c f_jc_c tc">
							<span class="fs16 cr_999">直播次数</span>
							<span class="fs18 cr_ff0 pt20">{{data.zbCount}}次</span>
						</el-col>
						<el-col :span="8" class="flex_c f_jc_c tc">
							<span class="fs16 cr_999">最低直播价</span>
							<span class="fs18 cr_ff0 pt20">{{data.min_zb_price}}元</span>
					</el-col>
					</div>
				</el-row>
			</e-card>
		</el-row>
		<div class="fs16 mt30 mb30">{{activeName === '1' ? '报名记录' : '合作主播场次'}}</div>
		<div v-if="activeName === '1'">
			<div class="table_title flex_r bg_table mb10">
				<span>商品信息</span>
				<span>优惠方式/优惠金额</span>
				<span>福利</span>
				<span>试样</span>
				<span>操作</span>
				<span>排期</span>
			</div>
			<div class="br_1_eee" v-for="item in goodsLists" v-bind:key="item">
				<div class="item_title flex_r f_ai_c f_jc_b tc bg_table pl20 pr20 fs12">
					<div>
						<el-checkbox></el-checkbox>
						<span class="pl20 pr20">流水号：</span>
						<span>商品来源：商家报名</span>
					</div>
					<div>
						<img class="mr10" width="16" height="16" src="//img.alicdn.com/imgextra//cb/34/TB1nMIPiqL7gK0jSZFBwu0ZZpXa.png" alt="">
						<span>{{ item.nick }}</span>
					</div>
					状态：<span style="color: red;">{{ item.statusInfo }}({{ item.note }})</span>
				</div>
				<div class="item_title bg_fff flex_r f_ai_c f_jc_b tc pl20 pr20 fs12 br_t_1_eee br_b_1_eee">
					<div>
						<span class="pr20">报名日期： {{ item.createtime }}</span>
						<span>意向佣金： {{ item.yx_rate }}%</span>
					</div>
					<div>
						<span class="pr20">招商： {{ item.username }}</span>
						<span>商家：{{ item.nick }}</span>
					</div>
				</div>
				<ul class="item_ul flex_r f_ai_c f_jc_b fs12">
					<li class="br_r_1_eee flex_r">
						<div class="br_r_1_eee pr10 mr10">
							<img height="80" width="80" :src="item.pict_url" alt="">
							<el-select class="mt20" v-model="item.cid">
								<el-option :label="item.name" :value="item.cid"></el-option>
							</el-select>
						</div>
						<div class="flex_c f_jc_b">
							<el-link class="lh_12" type="primary" :href="item.item_url" target="_blank">{{ item.title }}</el-link>
							<div>
								<span>在售价：</span>
								<span>{{ item.s_price }}</span>
							</div>
							<div>
								<span>直播价：</span>
								<span>{{ item.zb_price }}</span>
							</div>
							<div class="flex_r">
								<span class="w_50">规格：</span>
								<el-input type="textarea" :value="item.skuItem"></el-input>
							</div>
						</div>
					</li>
					<li class="br_r_1_eee flex_c f_jc_b">
						<div>
							<span class="cr_999">原价(划线价)：</span>
							<span>¥{{ item.s_price }}~{{ item.e_price }}</span>
						</div>
						<div>
							<span class="cr_999">在售价：</span>
							<span>¥{{ item.s_price }}~{{ item.e_price }}</span>
						</div>
						<div v-if="item.yh_type == 1">
							<span class="cr_999">-无：</span>
						</div>
						<div v-if="item.yh_type == 2">
							<span class="cr_999">-拍下立减：</span>
							<span>¥{{item.lj}}</span>
						</div>
						<div v-if="item.yh_type == 3">
							<span class="cr_999">-领券立减：</span>
							<span>¥{{item.lq_lj}}</span>
						</div>
						<div v-if="item.yh_type == 4">
							<span class="cr_999">-联系客服领取：</span>
							<span>¥{{item.kf_lj}}</span>
						</div>
						<div v-if="item.yh_type == 5">
							<span class="cr_999">-其他优惠：</span>
						</div>
						<div v-if="item.yh_type == 6">
							<span class="cr_999">-直接改价：</span>
						</div>
						<div>
							<span class="cr_999">=直播到手价：</span>
							<span>¥{{ item.zb_price }}~{{ item.zb_e_price }}</span>
						</div>
						<div>
							<span class="cr_999">历史最低价：</span>
							<span class="el-icon-s-data cr_ff0"></span>
						</div>
						<div class="tr">
							<!--							<el-link class="fs12" type="primary" @click="$refs.dialogRef.handleOpen()">修改优化</el-link>-->
						</div>
					</li>
					<li class="br_r_1_eee flex_c f_jc_b">
						<el-input :rows="3" type="textarea" placeholder="赠品：" :value="item.zp"></el-input>
						<el-input :rows="3" type="textarea" placeholder="抽奖：" :value="item.zbj_cj"></el-input>
					</li>
					<li class="br_r_1_eee">
						<el-input :rows="6" type="textarea" placeholder=""></el-input>
					</li>
					<li class="br_r_1_eee flex_c f_jc_a f_ai_c">
						<span class="cur_p" @click="$refs.dialogDataRef.handleOpen()">查看数据</span>
						<router-link tag="a" target="_blank" :to="{path:'/goodsDetail', query:{id:item.id}}">报名详情</router-link>
						<el-popover
								placement="top-start"
								title=""
								width="250"
								trigger="hover">
							<ul>
								<li class="flex_r f_jc_b mt2 mb2">
									<span>合同： 待发起</span>
<!--									<el-button @click="sponsorHandle(1)" type="danger">发起</el-button>-->
								</li>
								<li class="flex_r f_jc_b mt2 mb2">
									<span>保证金： 待发起</span>
<!--									<el-button @click="sponsorHandle(2)" type="danger">发起</el-button>-->
								</li>
								<li class="flex_r f_jc_b mt2 mb2">
									<span>服务费： 待发起</span>
<!--									<el-button @click="sponsorHandle(3)" type="danger">发起</el-button>-->
								</li>
								<li class="flex_r f_jc_b mt2 mb2">
									<span>链接： 待提交</span>
<!--									<el-button @click="sponsorHandle(4)" type="danger">发起</el-button>-->
								</li>
								<li class="flex_r f_jc_b mt2 mb2">
									<span>样品： 待寄送</span>
<!--									<el-button @click="sponsorHandle(5)" type="danger">发起</el-button>-->
								</li>
							</ul>
							<span slot="reference" class="cr_ff0 cur_p">交易服务</span>
						</el-popover>
						<router-link tag="a" target="_blank" :to="{path:'/goodsDetail', query:{id:item.id}}">报名详情</router-link>
					</li>
					<li class="br_r_1_eee">
						<el-select class="" v-model="item.statusInfo">
							<el-option :label="item.statusInfo" :value="item.statusInfo"></el-option>
						</el-select>
						<el-date-picker
								style="width:150px"
								class="mt10"
								v-model="value1"
								type="date"
								placeholder="选择排期">
						</el-date-picker>
						<div class="mt10 mb10" v-if="item.zbtime">
							<span class="cr_999">排期：</span>
							<span>{{ item.zbtime }}</span>
						</div>
						<div class="mt10 mb10" v-else>
							<span class="cr_999">排期：</span>
							<span>-</span>
						</div>
						<div v-if="item.act_id">
							<span class="cr_999">活动：</span>
							<span>{{ item.a_title }}</span>
						</div>
						<div v-else>
							<span class="cr_999">活动：</span>
							<span>-</span>
						</div>
					</li>
				</ul>
			</div>
			<el-pagination
				class="tr"
				:small="true"
				:current-page.sync="currentPage"
				@current-change="handleCurrentChange"
				:page-size="pageSize"
				:total="total">
			</el-pagination>
		</div>
		<e-table ref="tables" @query-Table-data="handleZbCurrentChange" v-if="activeName === '2'" :tableCols="commodityDetailsTableConf" :dataOrigin="dataAnchorOrigin">
			<template #operation>
			</template>
		</e-table>
		<e-dialog title="选择优惠" ref="dialogRef">
			<span>优惠方式：</span>
			<el-select>
				<el-option label="1" value="1"></el-option>
			</el-select>
		</e-dialog>
		<e-dialog width="1000px" title="查看数据" ref="dialogDataRef">
			<Empty />
		</e-dialog>
		<e-dialog :title="dialogTitle" ref="dialogSponsorRef">
			<e-form :formConfList="formConfListItem" />
		</e-dialog>
	</div>
</template>

<script>
import {businessTableConf, searchFormList, commodityDetailsTableConf} from '@data/business'
import {formConfList} from '@data/handCard'
import { optionPie} from '@data/dashboard'
export default {
	name: 'CommodityDetails',
	data() {
		return {
			form: {},
			commodityDetailsTableConf,
			businessTableConf,
			optionPie,
			searchFormList,
			formConfList,
			formConfListItem: [],
			dataOrigin: {
				data: []
			},
			dataAnchorOrigin: {
				data: []
			},
			activeName: "1",
			currentPage: 1,
			pageSize: 10,
			total: 0,
			dialogTitle: '',
			goods: {},
			data: {},
			goodsLists: [],
			id: '',
			zbtotal: 1,
			zbcurrentPage: 1,
		}
	},
	methods: {
		handleZbCurrentChange(data){
			this.$refs.tables.loading = true
			this.zbcurrentPage = data.currentPage
			this.GetAnchorEntrLists()
		},
		handleCurrentChange(val){
			this.currentPage = val
			this.GetEntrLists()
		},
		handleClick() {
			if(this.activeName == "1"){
				this.currentPage = 1
				this.GetEntrLists()
			} else {
				this.GetAnchorEntrLists()
			}
		},
		GetAnchorEntrLists(){
			let that = this
			this.dataAnchorOrigin.data = []
			this.zbcurrentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			this.get('?server=live.goods.detail.anchor.lists.get',{id:this.id,page:this.zbcurrentPage}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				let len = response.data.data.length
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataAnchorOrigin.data.push(response.data.data[i])
				}
				that.$refs.tables.total = response.data.total
				that.$refs.tables.initData()
				// console.log(that.goodsLists)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetEntrLists(){
			let that = this
			this.goodsLists = []
			this.get('?server=live.goods.detail.lists.get',{id:this.id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.goodsLists = response.data.data
				that.total = response.data.total
				that.cateLists = response.data.category
				// console.log(that.goodsLists)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetAnchorLists(){
			let that = this
			this.goodsLists = []
			this.get('?server=live.xse.group.by.anchor.get',{id:this.id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				let data = response.data;
				that.optionPie = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						top: '5%',
						left: 'center'
					},
					series: [
						{
							name: '访问来源',
							type: 'pie',
							radius: ['40%', '70%'],
							avoidLabelOverlap: false,
							itemStyle: {
								borderRadius: 10,
								borderColor: '#fff',
								borderWidth: 2
							},
							label: {
								show: false,
								position: 'center'
							},
							emphasis: {
								label: {
									show: true,
									fontSize: '20',
									fontWeight: 'bold'
								}
							},
							labelLine: {
								show: false
							},
							data: data
						}
					]
				};
				that.$refs.echarts.initEcharts();
				// console.log(that.goodsLists)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetDetail(){
			let that = this
			this.dataOrigin.data = []
			this.get('?server=live.goods.detail.info.get',{id:this.id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				// that.dataOrigin.data = response.data.data
				let len = response.data.lists.data.length
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.lists.data[i])
				}
				that.goods = response.data.goods
				that.data = response.data.data
				that.total = response.data.lists.total
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		dialogOpen() {
			this.$refs.dialogSponsorRef.handleOpen();
		},
		sponsorHandle(val) {
			switch(val) {
				case 1:
					this.dialogTitle = '通知商家签约';
					break
				case 2:
					this.dialogTitle = '新增服务费';
					break
				case 3:
					this.dialogTitle = '新增保证金';
					break
				case 4:
					this.dialogTitle = '佣金链接';
					break
				case 5:
					this.dialogTitle = '通知寄样';
					break
			}
			this.flag = false;
			this.formConfListItem = this.formConfList[val]; 
			this.dialogOpen();
		}
	},
	created() {

		this.id = this.$route.query.id

		if(!this.id){
			this.$message.error('请求非法')
		} else {
			this.GetDetail()
			this.GetEntrLists()
			this.GetAnchorLists()
		}

	}
}
</script>

<style lang="scss" scoped>
.account {
	height: 88px;
	.account_img {
		span {
			height: 20px;
			left: 0;
			right: 0;
			bottom: 1px;
			line-height: 20px;
			background-color: rgba($color: #000000, $alpha: .1);
		}
	}
	li {
		height: 88px;
		padding-left: 50px;
	}
}
.echart {
	height: 200px;
}
.w140 {
	width: 140px;	
}
.table_title {
	span {
		line-height: 40px;
		width: 15%;
		text-align: center;
		&:nth-child(1) {
			width: 25%;
		}
	}
}
.item_title {
	line-height: 30px;
}
.item_ul {
	li {
		width: 15%;
		padding: 10px;
		height: 140px;
		&:nth-child(1) {
			width: 25%;
		}
	}
}
</style>